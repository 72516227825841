import React, { useState } from "react";
import "./header.css";
import logo from "../../assets/abdulloyev-logo.png";

function Header() {
  /*=============== TOGGLE MENU ===============*/
  const [toggle, setToggle] = useState(false);
  return (
    <header className="header">
      <nav className="nav container">
        <a href={"/#"} className="nav__logo">
          <img src={logo} className="logo-png" alt="logoPng" />
        </a>

        <div className={toggle ? "nav__menu show-menu" : "nav__menu"}>
          <ul className="nav__list grid">
            {navItem &&
              navItem.map((nav) => (
                <li key={nav.id} className="nav__item">
                  <a href={nav?.href} className="nav__link active-link">
                    <i className={`uil uil-${nav?.icon} nav__icon`}></i>
                    {" " + nav?.name}
                  </a>
                </li>
              ))}
          </ul>

          <i
            className="uil uil-times nav__close"
            onClick={() => setToggle(!toggle)}
          ></i>
        </div>
        <div className="nav__toggle" onClick={() => setToggle(!toggle)}>
          <i className="uil uil-apps"></i>
        </div>
      </nav>
    </header>
  );
}

export default Header;

const navItem = [
  {
    id: 1,
    name: "Home",
    href: "#home",
    icon: "estate",
  },
  {
    id: 2,
    name: "About",
    href: "#about",
    icon: "user",
  },
  {
    id: 3,
    name: "Skills",
    href: "#skills",
    icon: "file-alt",
  },
  {
    id: 4,
    name: "Services",
    href: "#services",
    icon: "briefcase-alt",
  },
  {
    id: 5,
    name: "Portfolio",
    href: "#portfolio",
    icon: "scenery",
  },
  {
    id: 6,
    name: "Contact",
    href: "#contact",
    icon: "message",
  },
];
