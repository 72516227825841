import React from "react";
import { Link } from "react-router-dom";

function Social() {
  return (
    <div className="home__social">
      {socialItem &&
        socialItem.map((item) => (
          <Link key={item.id} to={item.href} target="_blank">
            <i className={`uil uil-${item?.icon} home__social-icon`}></i>
          </Link>
        ))}
    </div>
  );
}

export default Social;

const socialItem = [
  {
    id: 1,
    name: "Instagram",
    href: "https://www.instagram.com/ulugbekabdulloyev_/",
    icon: "instagram",
  },
  {
    id: 2,
    name: "Dribbble",
    href: "https://t.me/abdulloyev",
    icon: "telegram",
  },
  {
    id: 3,
    name: "GitHub",
    href: "https://github.com/ulugbekabdulloyev22",
    icon: "github",
  },
];
