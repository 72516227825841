import React, { useState } from "react";
import { SectionTitle } from "../";
import "./services.css";

function Services() {
  const [toggleState, setToggleState] = useState(null);

  const toggleTab = (ind) => {
    setToggleState(ind);
  };

  return (
    <section className="section services" id="services">
      <SectionTitle title={"Services"} subtitle={"What i offer"} />

      <div className="sercvices__container container grid">
        {data.map((item, ind) => (
          <div key={item.id} className="services__content">
            <div className="">
              <i className="uil uil-web-grid services__icon"></i>
              <h3
                className="services__title"
                dangerouslySetInnerHTML={{
                  __html: item.title,
                }}
              ></h3>

              <span className="services__button" onClick={() => toggleTab(ind)}>
                View More
                <i className="uil uil-arrow-right services__button-icon"></i>
              </span>

              <div
                className={`services__modal ${
                  toggleState === ind ? "active-modal" : ""
                }`}
              >
                <div className="services__modal-content">
                  <i
                    className="uil uil-times services__modal--close"
                    onClick={() => setToggleState(null)}
                  ></i>

                  <h3 className="services__modal-title">
                    {item.title.replace(/<br\s*\/?>/gi, "\n")}
                  </h3>
                  <p className="service__modal-description">
                    {item.description}
                  </p>

                  <ul className="services__modal-services grid">
                    {item.info.map((info) => (
                      <li key={info} className="services__modal-service">
                        <i className="uil uil-check-circle services-modal-icon"></i>

                        <p className="services__modal-info">{info}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;

const data = [
  {
    id: 1,
    title: "Product <br /> Designer",
    description:
      "Service with more than 3 years of experience.Providing quality work to clients and companies.",
    info: [
      "I develop the user interface.",
      "Web page development.",
      "I create ux element interactions.",
      "I position your company brand.",
      "Design and mockups of products for companies.",
    ],
  },
  {
    id: 2,
    title: "Ui/Ux <br> Designer",
    description:
      "Service with more than 3 years of experience.Providing quality work to clients and companies.",
    info: [
      "I develop the user interface.",
      "Web page development.",
      "I create ux element interactions.",
      "I position your company brand.",
      "Design and mockups of products for companies.",
    ],
  },
  {
    id: 3,
    title: "Visual <br> Designer",
    description:
      "Service with more than 3 years of experience.Providing quality work to clients and companies.",
    info: [
      "I develop the user interface.",
      "Web page development.",
      "I create ux element interactions.",
      "I position your company brand.",
      "Design and mockups of products for companies.",
    ],
  },
];
