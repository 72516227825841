import React, { useState } from "react";
import "./qualification.css";

const Qualification = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (ind) => {
    setToggleState(ind);
  };

  return (
    <section className="qualification section">
      <h2 className="section__title">Qualification</h2>
      <span className="section__subtitle">My personel journey</span>

      <div className="qualification__container container">
        <div className="qualification__tabs">
          {data &&
            data?.map((item) => (
              <div
                key={item.id}
                className={
                  toggleState === item.id
                    ? `qualification__button button--flex qualification__active`
                    : "qualification__button button--flex"
                }
                onClick={() => toggleTab(item.id)}
              >
                <i className={`uil uil-${item.icon} qualification__icon`}></i>{" "}
                {item?.butonName}
              </div>
            ))}
        </div>

        <div className="qualification__sections">
          {data &&
            data?.map((item) => (
              <div
                key={item.id}
                className={
                  toggleState === item.id
                    ? "qualification__content qualification__content-active"
                    : "qualification__content"
                }
              >
                {item.qualification?.map((qua) => (
                  <div key={qua.id} className="qualification__data">
                    {qua.id % 2 === 0 ? (
                      <>
                        <div></div>

                        <div>
                          <span className="qualification__rounder"></span>
                          <span className="qualification__line"></span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    <div>
                      <h3 className="qualification__title">{qua?.title}</h3>
                      <span className="qualification__subtitle">
                        {qua?.subtitle}
                      </span>
                      <div className="qualification__calendat">
                        <i className="uil uil-calendar-alt"></i> {qua?.date}
                      </div>
                    </div>

                    {qua.id % 2 === 0 ? (
                      ""
                    ) : (
                      <div>
                        <span className="qualification__rounder"></span>
                        <span className="qualification__line"></span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};

export default Qualification;

const data = [
  {
    id: 1,
    butonName: "Education",
    icon: "graduation-cap",
    qualification: [
      {
        id: 1,
        title: "Web Design",
        subtitle: "Spain - Institute",
        date: "2021 - Present",
      },
      {
        id: 2,
        title: "Art Director",
        subtitle: "Spain - Institute",
        date: "2020 - 2021",
      },
      {
        id: 3,
        title: "Web Development",
        subtitle: "Spain - Institute",
        date: "2018 - 2020",
      },
      {
        id: 4,
        title: "UX Expert",
        subtitle: "Spain - Institute",
        date: "2017 - 2018",
      },
    ],
  },
  {
    id: 2,
    butonName: "Experience",
    icon: "briefcase-alt",
    qualification: [
      {
        id: 1,
        title: "Product Designer",
        subtitle: "Microsoft - Spain",
        date: "2021 - Present",
      },
      {
        id: 2,
        title: "UX Designer",
        subtitle: "Apple Inc - Spain",
        date: "2020 - 2021",
      },
      {
        id: 3,
        title: "Web Designer",
        subtitle: "Figma - Spain",
        date: "2018 - 2020",
      },
    ],
  },
];
