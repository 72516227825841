import Backend from "./backend";
import Frontend from "./frontend";
import "./skills.css";
import { SectionTitle } from "../";

const Skills = () => {
  return (
    <section className="skills section" id="skills">
      <SectionTitle title={"Skills"} subtitle={"My technical level"} />

      <div className="skills__container container grid">
        <Frontend data={data} />

        <Backend data={data} />
      </div>
    </section>
  );
};

export default Skills;

const data = [
  {
    id: 1,
    type: "frontend",
    name: "HTML",
    lavel: "Basic",
  },
  {
    id: 2,
    type: "frontend",
    name: "CSS",
    lavel: "Advanced",
  },
  {
    id: 3,
    type: "frontend",
    name: "JavaScript",
    lavel: "Intermediate",
  },
  {
    id: 4,
    type: "frontend",
    name: "Bootstrap",
    lavel: "Intermediate",
  },
  {
    id: 5,
    type: "frontend",
    name: "Git",
    lavel: "Intermediate",
  },
  {
    id: 6,
    type: "frontend",
    name: "React",
    lavel: "Intermediate",
  },
  {
    id: 7,
    type: "backend",
    name: "PHP",
    lavel: "Intermediate",
  },
  {
    id: 8,
    type: "backend",
    name: "Node Js",
    lavel: "Basic",
  },
  {
    id: 9,
    type: "backend",
    name: "Python",
    lavel: "Intermediate",
  },
  {
    id: 10,
    type: "backend",
    name: "MySQL",
    lavel: "Intermediate",
  },
  {
    id: 11,
    type: "backend",
    name: "Firebase",
    lavel: "Intermediate",
  },
  {
    id: 12,
    type: "backend",
    name: "Hygraph",
    lavel: "Intermediate",
  },
];
