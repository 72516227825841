import React from "react";
import "./App.css";
import {
  About,
  Header,
  Home,
  Qualification,
  Services,
  Skills,
} from "./components";

function App() {
  return (
    <>
      <Header />
      <div className="main">
        <Home />
        <About />
        <Skills />
        <Services />
        <Qualification />
      </div>
    </>
  );
}

export default App;
