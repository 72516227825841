import React from "react";

function Info() {
  return (
    <div className="about__info grid">
      {aboutInfo &&
        aboutInfo.map((item) => (
          <div key={item.id} className="about__box">
            <i className={`bx bx-${item.icon} about__icon`}></i>
            <h3 className="about__title">{item.title}</h3>
            <span className="about__subtitle">{item.subtitle}</span>
          </div>
        ))}
    </div>
  );
}

export default Info;

const aboutInfo = [
  {
    id: 1,
    title: "Experience",
    subtitle: "8 Years Working",
    icon: "award",
  },
  {
    id: 2,
    title: "Completed",
    subtitle: "48 + Projects",
    icon: "briefcase-alt-2",
  },
  {
    id: 3,
    title: "Support",
    subtitle: "Online 24/7",
    icon: "support",
  },
];
