const Backend = ({ data }) => {
  return (
    <div className="skills__content">
      <h3 className="skills__title"> Backend Developer</h3>

      <div className="skills__box">
        <div className="skills__group">
          {data
            ?.filter((a) => a.type === "backend")
            .map((item) => (
              <div key={item.id} className="skills__data">
                <i className="bx bx-badge-check"></i>

                <div className="">
                  <h3 className="skills__name">{item.name}</h3>
                  <span className="skills__lavel">{item.lavel}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Backend;
